export const Collections = {
  tickets: 'tickets',
  ticketTypes: 'ticketTypes',
  mails: 'mails',
  mailTemplates: 'mail_templates',
  content: 'content',
  orders: 'orders',
  payments: 'payments',
};

export type Order = {
  orderId: string;
  email: string;
  ticketIds: string[];
  price: number;
  createdAt: string;
  knowsFromOrga: string;
  heardAboutUs: string;
  paidAt: string | null;
  paymentId: string | null;
  canceledAt?: string | null;
};

export type Payment = {
  paymentId: string;
  orderId: string;
  createdAt: string;
  stripe: any;
};

export type Ticket = {
  ticketId: string;
  orderId: string;
  name: string;
  email: string;
  supportsWith: string;
  ticketType: TicketType;
  price: number;
  createdAt: string;
  canceledAt?: string | null;
};

export type TicketType = {
  name: 'normal' | 'soli' | 'parking';
  isPerson: boolean;
  label: string;
  price: number;
};
