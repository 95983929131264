import { functions } from '@/firebase';
import { httpsCallable } from 'firebase/functions';
import {
  GetPaymentUrlRequest,
  GetPaymentUrlResponse,
  Order,
  PlaceOrderRequest,
} from '@packages/types';

export const placeOrderFn = httpsCallable<PlaceOrderRequest, { order: Order }>(
  functions,
  'placeOrder',
);
export const getPaymentUrl = httpsCallable<GetPaymentUrlRequest, GetPaymentUrlResponse>(
  functions,
  'getPaymentUrl',
);
