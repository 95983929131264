import { Link, NavLink, Outlet } from 'react-router-dom';
import background from '@/assets/bg.jpeg';
import video from '@/assets/background.mp4';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@packages/ui';
import './layout.css';
import { useTicketing } from './hooks/useTicketing';
import { useCountdown } from './hooks/useCountdown';
import { Countdown } from './components/Countdown';

export const Layout = () => {
  const { t, i18n } = useTranslation();
  const { showTicketing } = useTicketing();
  const { secondsLeft } = useCountdown();

  const hasTicketTimeout = secondsLeft > 0;

  const toggleLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('en');
    }
  };

  const languageEmoji = i18n.language === 'en' ? '🇩🇪' : '🇬🇧';
  return (
    <div
      className="h-screen w-screen overflow-hidden relative"
      style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <video
        autoPlay
        muted
        playsInline
        loop
        className="absolute w-full h-screen object-cover pointer-events-none">
        <source src={video} type="video/mp4" />
      </video>
      <div className="h-screen w-screen bg-background absolute opacity-80"></div>
      <div className="w-screen h-screen z-10 absolute overflow-scroll" id="scroll-container">
        <div className="h-full flex flex-col">
          <div className="p-4 grow flex justify-center w-full max-w-6xl mx-auto">
            <main className="grow text-white relative rounded-xl p-8 border-white backdrop-blur-md pb-4">
              <div className="absolute top-5 right-5">
                <Tooltip content={t('changeLanguage')}>
                  <button onClick={toggleLanguage} className="text-xl leading-[12px]">
                    {languageEmoji}
                  </button>
                </Tooltip>
              </div>
              <nav className="grid grid-cols-1 md:grid-cols-1 w-full gap-4 justify-center items-center mb-16">
                <a href="/">
                  <div className="flex flex-col justify-center items-center">
                    <h1 className="text-center title-font text-6xl animated-morf">Wüst & Wild</h1>

                    <h2 className="subtitle-font text-3xl mt-2">29.08. - 31.-08.2025</h2>
                  </div>
                </a>

                {hasTicketTimeout ? null : (
                  <div className="flex">
                    <div className="md:flex md:flex-wrap md:justify-between grid grid-cols-2 grow gap-4 lg:text-[2em] md:text-[1.5em] text-[1.2em]">
                      <NavLink to="/info" className="font-bold menu-link">
                        {t('info')}
                      </NavLink>

                      <NavLink to="/tickets" className="font-bold menu-link">
                        {t('tickets')}
                      </NavLink>

                      <NavLink to="/awareness" className="font-bold menu-link">
                        {t('awareness')}
                      </NavLink>
                      <NavLink to="/program" className="font-bold menu-link">
                        {t('program')}
                      </NavLink>
                    </div>
                  </div>
                )}
              </nav>

              <div>{hasTicketTimeout ? <Countdown /> : <Outlet />}</div>
            </main>
          </div>
          <footer className="border-t py-2 backdrop-blur bg-white/20 text-green-100 font-bold">
            <div className="container space-x-8 text-center flex justify-center items-center text-xs">
              <Link to="/imprint">{t('imprint')}</Link>
              <Link to="/privacy">{t('privacy')}</Link>
              <Tooltip content={t('changeLanguage')}>
                <button onClick={toggleLanguage} className="text-lg leading-[12px]">
                  {languageEmoji}
                </button>
              </Tooltip>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};
