import { useLocation, useParams } from 'react-router-dom';
import { CheckIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { getOrderQuery, getTicketQuery } from '@/api/queries';
import { useTranslation } from 'react-i18next';
import { Button } from '@packages/ui';

export const TicketDetails = () => {
  const { t } = useTranslation();
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useQuery(getTicketQuery(ticketId ?? ''));
  const { data: order } = useQuery(getOrderQuery(ticket?.orderId ?? ''));

  if (!ticket) return null;
  if (!order) return null;

  if (ticket.canceledAt) {
    return (
      <>
        <div className="bg-background/40 p-4 border border-destructive rounded">
          <h2 className="mt-2 font-bold text-warning">{t('ticketCanceled')}</h2>
          <span className="text-destructive">{t('ticketCanceled_message')}</span>
          <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
            <dt>{t('ticketNumber')}:</dt>
            <dd>{ticket.ticketId}</dd>
            <dt>Email:</dt>
            <dd>{ticket.email}</dd>
          </dl>
        </div>
      </>
    );
  }

  return (
    <div>
      <h1 className="font-bold text-lg">{t('ticketStatus')}</h1>

      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        <dt>Payment:</dt>
        <dd>
          {order.order.paidAt ? (
            <div className="flex text-success items-center">
              <CheckIcon className="mr-2" />
              {t('paymentReceivedOn')}{' '}
              {Intl.DateTimeFormat('de-DE').format(new Date(order.order.paidAt))}
            </div>
          ) : (
            <div className="text-destructive">
              {t('paymentPending_message')}{' '}
              <a href={`/orders/${ticket.orderId}`} className="ml-2">
                <Button variant={'link'}>{t('payNow')}</Button>
              </a>
            </div>
          )}
        </dd>

        <dt>{t('orderNumber')}:</dt>
        <dd>
          <a href={`/orders/${ticket.orderId}`}>{ticket.orderId}</a>
          <div className="mt-2 mb-4">
            <a href={`/orders/${ticket.orderId}`}>
              <Button>{t('gotoOrder')}</Button>
            </a>
          </div>
        </dd>
        <dt>{t('ticketNumber')}:</dt>
        <dd>{ticket.ticketId}</dd>
        <dt>Name:</dt>
        <dd>{ticket.name}</dd>
        <dt>Email:</dt>
        <dd>{ticket.email}</dd>
        <dt>{t('ticketPrice')}:</dt>
        <dd>{ticket.price} EUR</dd>

        <a
          className="mt-8 block"
          href="https://docs.google.com/spreadsheets/d/1UD_orcE0ynoSDdVDrWL3hxMTHo7bfbVE3Gkq3sF6d-A/edit?gid=0#gid=0"
          target="_blank"
          rel="noreferrer">
          <Button variant={'default'}>{t('supportPlan')}</Button>
        </a>
      </dl>
    </div>
  );
};
