import { queryOptions } from '@tanstack/react-query';
import { FirebaseClient } from './firebaseClient';

const client = new FirebaseClient();

export const QUERY_KEYS = {
  TICKETS: 'tickets',
  ORDERS: 'orders',
  CONTENT: 'content',
};

export const appDataQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.TICKETS, 'appData'],
    queryFn: async () => {
      return client.getAppData();
    },
    staleTime: 1000 * 60,
  });
};
export const ticketTypeQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.TICKETS, 'ticketTypes'],
    queryFn: async () => {
      return client.getTicketTypes();
    },
    staleTime: 1000 * 60,
  });
};

export const getTicketQuery = (id: string) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.TICKETS, id],
    queryFn: async () => {
      return client.getTicket(id);
    },
    staleTime: 1000 * 60,
  });
};

export const getOrderQuery = (id: string) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.ORDERS, id],
    queryFn: async () => {
      return client.getOrder(id);
    },
    staleTime: 1000 * 60,
  });
};

export const getHomeQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CONTENT, 'home'],
    queryFn: async () => {
      return client.getHome();
    },
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });
};
export const getAwarenessQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CONTENT, 'awareness'],
    queryFn: async () => {
      return client.getAwareness();
    },
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });
};
export const getProgramQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CONTENT, 'program'],
    queryFn: async () => {
      return client.getProgram();
    },
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });
};

export const getFAQsQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CONTENT, 'faqs'],
    queryFn: async () => {
      return client.getFaqs();
    },
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
  });
};
