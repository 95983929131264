import { useParams } from 'react-router-dom';
import { CheckIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { getOrderQuery } from '@/api/queries';
import { useTranslation } from 'react-i18next';
import { FirebaseClient } from '@/api/firebaseClient';
import { Button } from '@packages/ui';

const client = new FirebaseClient();

export const OrderDetails = () => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const { data } = useQuery(getOrderQuery(orderId ?? ''));

  if (!data) return null;

  const makePayment = async () => {
    const getPaymentUrl = await client.getPaymentUrl(data.order.orderId);
    location.href = getPaymentUrl.data.url;
  };

  if (data.order.canceledAt) {
    return (
      <>
        <div className="bg-background/40 p-4 border border-destructive rounded">
          <h2 className="mt-2 font-bold text-warning">{t('orderCanceled')}</h2>
          <span className="text-destructive">{t('ticketCanceled_message')}</span>
          <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
            <dt>{t('orderNumber')}:</dt>
            <dd>{data.order.orderId}</dd>
            <dt>Email:</dt>
            <dd>{data.order.email}</dd>
          </dl>
        </div>
      </>
    );
  }

  return (
    <div>
      <h1 className="font-bold text-lg">{t('orderStatus')}</h1>

      {!data.order?.paidAt ? (
        <>
          <div className="bg-background/40 p-4 border border-warning rounded">
            <h2 className="mt-2 font-bold text-warning">{t('paymentPending')}</h2>
            <div className="text-destructive">{t('paymentPending_message')}</div>
            <Button onClick={makePayment} className="mt-4">
              {t('payNow')}
            </Button>
          </div>
        </>
      ) : null}

      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        {data.order.paidAt ? (
          <>
            <dt>Payment:</dt>
            <dd>
              <div className="flex text-success items-center">
                <CheckIcon className="mr-2" />
                {t('paymentReceivedOn')}{' '}
                {Intl.DateTimeFormat('de-DE').format(new Date(data.order.paidAt))}
              </div>
            </dd>
          </>
        ) : null}

        <dt>{t('orderNumber')}:</dt>
        <dd className="text-3xl">{data.order.orderId}</dd>

        <dt>Email:</dt>
        <dd className="text-2xl">{data.order.email}</dd>
        <dt>{t('orderPrice')}:</dt>
        <dd className="text-2xl">{data.order.price} EUR</dd>
        <dt>Tickets:</dt>
        <section className="flex flex-col gap-2 mt-4">
          {data.tickets.map((ticket) => (
            <div key={ticket.ticketId} className="border p-4 rounded flex justify-between">
              <div className="space-x-4">
                <a className="font-bold text-2xl" href={`/tickets/${ticket.ticketId}`}>
                  {ticket.name}
                </a>
                <span>{'|'}</span>
                <span className="text-muted-foreground text-xl">{ticket.ticketType.label}</span>
                <a href={`/tickets/${ticket.ticketId}`} className="text-primary">
                  {t('showTicket')}
                </a>
              </div>
              <div className="text-3xl font-bold">
                <div>{ticket.ticketType.price} EUR</div>
              </div>
            </div>
          ))}
        </section>
      </dl>
    </div>
  );
};
