import { NotFoundPage } from './pages/NotFoundPage';
import { TicketsPage } from './pages/Tickets';
import { FaqPage } from './pages/FAQ';
import { ImprintPage } from './pages/ImprintPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { TicketDetails } from './pages/TicketDetails';
import { Home } from './pages/Home';
import { AwarenessPage } from './pages/AwarenessPage';
import { ProgramPage } from './pages/ProgramPage';
import { OrderDetails } from './pages/OrderDetails';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/tickets',
    element: <TicketsPage />,
  },
  {
    path: '/tickets/:ticketId',
    element: <TicketDetails />,
  },
  {
    path: '/orders/:orderId',
    element: <OrderDetails />,
  },
  {
    path: '/info',
    element: <FaqPage />,
  },
  {
    path: '/awareness',
    element: <AwarenessPage />,
  },
  {
    path: '/program',
    element: <ProgramPage />,
  },
  {
    path: '/imprint',
    element: <ImprintPage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPage />,
  },
  { path: '*', element: <NotFoundPage /> },
];
