import { FirebaseClient } from '@/api/firebaseClient';
import { appDataQuery, QUERY_KEYS, ticketTypeQuery } from '@/api/queries';
import { useInvalidatingMutation } from '@/hooks/useInvalidatingMutation';
import { PlaceOrderRequest } from '@packages/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Label, toast, Popover, PopoverTrigger, PopoverContent } from '@packages/ui';
import { useTranslation } from 'react-i18next';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useTicketing } from '@/hooks/useTicketing';

type TicketItem = PlaceOrderRequest['tickets'][number] & { rowId: string };
export const TicketsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showTicketing } = useTicketing();
  const ticketsRef = useRef<HTMLDivElement>(null);

  const client = new FirebaseClient();

  const [orga, setOrga] = useState('');
  const [heardOfUsFrom, setHeardOfUsFrom] = useState('');
  const [error, setError] = useState('');

  const [tickets, setTickets] = useState<TicketItem[]>([]);

  const { data: appData } = useQuery(appDataQuery());
  const { data: ticketTypes, isLoading } = useQuery(ticketTypeQuery());

  const ticketsWithTicketTypes = useMemo(
    () =>
      tickets.map((ticket) => ({
        ticket,
        ticketType: (ticketTypes ?? []).find((t) => t.name === ticket.ticketTypeName),
      })),
    [tickets, ticketTypes],
  );

  const { mutateAsync: placeOrder } = useInvalidatingMutation([QUERY_KEYS.ORDERS], {
    mutationFn: async (data: PlaceOrderRequest) => client.placeOrder(data),
  });

  const ticketsWithType = useMemo(
    () =>
      tickets.map((ticket) => ({
        ...ticket,
        ticketType: ticketTypes?.find((type) => type.name === ticket.ticketTypeName),
      })),
    [tickets, ticketTypes],
  );

  const totalPrice = useMemo(
    () => ticketsWithType.reduce((acc, ticket) => acc + (ticket.ticketType?.price ?? 0), 0),
    [ticketsWithType],
  );

  const addTicket = (type: string) => {
    if (tickets.length >= 5) {
      toast({
        title: 'Too many tickets',
        variant: 'destructive',
        duration: 3000,
        description: 'You can add only 5 tickets at once.',
      });
      return;
    }
    setTickets((prev) => [
      ...prev,
      {
        email: '',
        name: '',
        supportsWith: '',
        rowId: Math.random().toString(36).substring(7),
        ticketTypeName: type,
      },
    ]);

    setTimeout(() => {
      ticketsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 100);
  };

  const onSubmit = async () => {
    setError('');
    const form: HTMLFormElement | null = document.getElementById(
      'ticketForm',
    ) as HTMLFormElement | null;
    if (!form) return;
    const isValid = form.reportValidity();
    if (!isValid) return;

    if (!tickets.length) {
      setError(t('pleaseAddTicket'));
      return;
    }

    try {
      const result = await placeOrder({
        tickets,
        email: tickets[0].email,
        knowsFromOrga: orga,
        heardAboutUs: heardOfUsFrom,
      });
      navigate(`/orders/${result.data.order.orderId}`);
    } catch (e) {
      console.error(t('errorBuyingTicket'), e);
      setError(t('ticketCouldNotBeBought'));
    }
  };

  const updateTicket = (ticket: TicketItem) => {
    setTickets((prev) => prev.map((t) => (t.rowId === ticket.rowId ? ticket : t)));
  };

  const deleteTicket = (ticket: TicketItem) => {
    setTickets((prev) => prev.filter((t) => t.rowId !== ticket.rowId));
  };

  if (isLoading || !appData) {
    return null;
  }

  if (appData.ticketsSold >= appData.ticketsAvailable) {
    return <div>{t('noTicketsAvailable')}</div>;
  }

  if (!showTicketing) {
    return t('ticketing_contact_us');
  }

  return (
    <div className="flex flex-col items-center">
      <form id="ticketForm" className="flex flex-col w-full gap-2 py-4 max-w-screen">
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-12">
          <div>
            <h2 className="w-full text-2xl border-b mb-4">Unsere Tickets</h2>
            {/* TICKET SELECTION */}
            <section className="flex flex-col w-full gap-8 mb-12">
              {(ticketTypes ?? []).map((type) => (
                <article key={type.name} className="">
                  <div className="flex flex-col lg:flex-row gap-8 justify-between mb-3">
                    <div>
                      <div className="flex space-x-4">
                        <h1 className="text-2xl font-semibold mb-1">{type.label}</h1>
                        {type.name === 'soli' ? (
                          <Popover>
                            <PopoverTrigger>
                              <span className="flex w-5 h-5 text-xs bg-white/70 items-center justify-center hover:cursor-pointer text-background rounded-full">
                                i
                              </span>
                            </PopoverTrigger>
                            <PopoverContent align={'start'}>
                              {t('ticket_description_soli_notes')}
                            </PopoverContent>
                          </Popover>
                        ) : null}
                      </div>

                      <p className="text-sm">{t(`ticket_description_${type.name}`)}</p>
                    </div>
                    <div className=" whitespace-nowrap font-bold text-3xl">{type.price} EUR</div>
                  </div>

                  <Button onClick={() => addTicket(type.name)}>{t('add_ticket')}</Button>
                </article>
              ))}
            </section>

            <section ref={ticketsRef}>
              {/* LIST OF ACTIVE TICKETS */}
              {ticketsWithTicketTypes.map((lineItem) => (
                <article
                  key={lineItem.ticket.rowId}
                  className="border border-primary bg-primary/10 rounded-xl p-8 mb-8">
                  <header>
                    <div className="flex justify-between">
                      <h1 className="font-bold text-2xl mb-2">{lineItem.ticketType?.label}</h1>
                      <button
                        className="rounded-full bg-foreground/80 text-background flex justify-center items-center hover:bg-foreground w-8 h-8"
                        onClick={() => deleteTicket(lineItem.ticket)}>
                        <Cross1Icon />
                      </button>
                    </div>
                    <div className="text-3xl font-bold mb-4">{lineItem.ticketType?.price} EUR</div>
                  </header>

                  {lineItem.ticket.ticketTypeName === 'parking' ? (
                    <p className="border-l-2 border-gray-500 pl-4 text-muted-foreground mb-4">
                      {t('parking_ticket_note')}
                    </p>
                  ) : null}

                  <div>
                    <Label className="mt-2">Email</Label>
                    <Input
                      className="p-2 border rounded w-full"
                      type="email"
                      required
                      placeholder="Email"
                      value={lineItem.ticket.email}
                      onChange={(input) =>
                        updateTicket({ ...lineItem.ticket, email: input.target.value })
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <Label>{t('full_name')}</Label>
                    <Input
                      className="p-2 border rounded w-full"
                      name="name"
                      required
                      type="text"
                      value={lineItem.ticket.name}
                      onChange={(input) =>
                        updateTicket({ ...lineItem.ticket, name: input.target.value })
                      }
                      placeholder="First Last"
                    />
                  </div>

                  {lineItem.ticket.ticketTypeName === 'parking' ? null : (
                    <div className="mt-2">
                      <Label>{t('supports_with')}</Label>
                      <Input
                        className="p-2 border rounded w-full"
                        name="name"
                        required
                        type="text"
                        value={lineItem.ticket.supportsWith}
                        onChange={(input) =>
                          updateTicket({ ...lineItem.ticket, supportsWith: input.target.value })
                        }
                        placeholder="e.g. Bar, Aufbau, Abbau, Workshop, etc."
                      />
                    </div>
                  )}
                </article>
              ))}
            </section>
            <hr className="my-8" />
            <div>
              <Label>{t('tickets_orga_label')}</Label>
              <Input
                className="p-2 border rounded w-full"
                name="orga"
                required
                type="text"
                value={orga}
                onChange={(input) => setOrga(input.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="mt-2">
              <Label>{t('tickets_heard_label')}</Label>
              <Input
                className="p-2 border rounded w-full"
                name="orga"
                required
                type="text"
                value={heardOfUsFrom}
                onChange={(input) => setHeardOfUsFrom(input.target.value)}
                placeholder={t('tickets_placeholder_heard')}
              />
            </div>
          </div>
          <div className="lg:text-right relative">
            <div className="sticky top-20">
              <div className="cart mt-8 mb-4 flex flex-col text-xl">
                <span>{t('totalPrice')}</span>
                <span className="font-bold text-3xl">{totalPrice} EUR</span>
              </div>
              <Button
                onClick={() => onSubmit()}
                size="lg"
                variant={'default'}
                className="px-4 py-4 rounded text-white"
                disabled={!tickets.length}>
                {t('order_tickets')}
              </Button>
              {error && <div className="text-red-500">{error}</div>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
