import { useEffect, useMemo, useState } from 'react';

const getUtcOffsetNow = () => Date.now() + 60 * 60 * 1000;

export const useCountdown = () => {
  const [secondsLeft, setSecondsLeft] = useState(1);

  useEffect(() => {
    const now = getUtcOffsetNow();
    const ticketingStart = new Date('2025-03-15T00:00:00Z').getTime();

    if (now < ticketingStart) {
      const interval = setInterval(() => {
        const diff = ticketingStart - getUtcOffsetNow();
        if (diff <= 0) {
          clearInterval(interval);
          setSecondsLeft(0);
        } else {
          setSecondsLeft(Math.floor(diff / 1000));
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setSecondsLeft(0);
    }
  }, []);

  const timeLeft = useMemo(() => {
    if (secondsLeft === 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const days = Math.floor(secondsLeft / 86400);
    const hours = Math.floor((secondsLeft % 86400) / 3600);
    const minutes = Math.floor((secondsLeft % 3600) / 60);
    const seconds = secondsLeft % 60;

    return { days, hours, minutes, seconds };
  }, [secondsLeft]);

  return { secondsLeft, timeLeft };
};
