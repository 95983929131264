export const ImprintPage = () => {
  return (
    <>
      <h1>Impressum</h1>
      <h2 className="mt-2 text-foreground/80">Verantwortlich für Inhalt</h2>
      <p>Matthias Sondermann</p>
      <p>Wüst und Wild n.e.V., Wisbyer Str. 62, 10439 Berlin</p>
      <h2 className="mt-2 text-foreground/80">Kontakt</h2>
      E-Mail: info@wuest-und-wild.de <br />
      Website: wuest-und-wild.de <br />
    </>
  );
};
