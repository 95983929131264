import {
  CollectionReference,
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  DocumentData,
} from 'firebase/firestore';
import { placeOrderFn, getPaymentUrl } from './functions';
import { PlaceOrderRequest, Collections, Order, Ticket, TicketType } from '@packages/types';
import { firestore } from '@/firebase';

type FAQ = { q: string; a: string };

export class FirebaseClient {
  private ticketCollection: CollectionReference<Ticket, Ticket>;
  private orderCollection: CollectionReference<Order, Order>;
  private ticketTypeCollection: CollectionReference<TicketType, TicketType>;
  private contentCollection: CollectionReference<DocumentData, DocumentData>;

  constructor() {
    this.ticketTypeCollection = collection(
      firestore,
      Collections.ticketTypes,
    ) as CollectionReference<TicketType, TicketType>;

    this.orderCollection = collection(firestore, Collections.orders) as CollectionReference<
      Order,
      Order
    >;

    this.ticketCollection = collection(firestore, Collections.tickets) as CollectionReference<
      Ticket,
      Ticket
    >;
    this.contentCollection = collection(firestore, Collections.content);
  }

  async getAppData() {
    const ticketingDataDoc = await getDoc(doc(collection(firestore, 'app'), 'ticketing'));
    const ticketingData = ticketingDataDoc.data() as {
      ticketsSold: number;
      ticketsAvailable: number;
    };

    return ticketingData;
  }

  async getPaymentUrl(orderId: string) {
    return getPaymentUrl({ orderId });
  }

  async getTicketTypes(): Promise<TicketType[]> {
    const ticketTypeDocs = await getDocs(query(this.ticketTypeCollection));

    return ticketTypeDocs.docs.map((doc) => doc.data());
  }

  async getTicket(id: string) {
    try {
      const d = doc(this.ticketCollection, id);
      const result = await getDoc(d);
      return result.data() ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getOrder(id: string) {
    try {
      const d = doc(this.orderCollection, id);
      const result = await getDoc(d);
      const order = result.data() ?? null;
      if (!order) return null;
      const ticketSnapshots = await Promise.all(
        order.ticketIds.map((ticketId) => {
          return getDoc(doc(this.ticketCollection, ticketId));
        }),
      );
      const tickets = ticketSnapshots.map((doc) => doc.data()).filter(Boolean) as Ticket[];
      return { order, tickets };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getHome() {
    try {
      const d = doc(this.contentCollection, 'home');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getAwareness() {
    try {
      const d = doc(this.contentCollection, 'awareness');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getProgram() {
    try {
      const d = doc(this.contentCollection, 'program');
      const result = await getDoc(d);
      const data = result.data() as { de: string; en: string };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getFaqs() {
    try {
      const d = doc(this.contentCollection, 'faqs');
      const result = await getDoc(d);
      const data = result.data() as { de: FAQ[]; en: FAQ[] };
      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async placeOrder(data: PlaceOrderRequest) {
    return placeOrderFn(data);
  }
}
